import tabs from '../../common/components/tabs/tabs.vue';
import videoPreview from '../../common/components/video-preview/video-preview.vue';
import mevp from '../../modules/MEVP/mevp';

export default {
	extends: tabs,
	components: {
		videoPreview,
		mevp
	}
};
