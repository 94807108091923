import './layout.scss';
import Vue from 'vue';
import VueTableOfContent from '@ui-common/vue-table-of-content';
import macc from '../../modules/MACC/macc';
import mjbl from '../../modules/MJBL/mjbl.vue';
import mstb from '../../modules/MSTB/mstb';
import meda from '../../modules/MEDA/meda';
import mevp from '../../modules/MEVP/mevp';
import mnib from '../../modules/MNIB/mnib';
import msup from '../../modules/MSUP/msup';
import mtab from '../../modules/MTAB/mtab';
import mfch from '../../modules/MFCH/mfch';
import meok from '../../modules/MEOK/meok';
import mltl from '../../modules/MLTL/mltl';
import mdtb from '../../modules/MDTB/mdtb';
import mtbl from '../../modules/MTBL/mtbl';
import mslc from '../../modules/MSLC/mslc';
import masd from '../../modules/MASD/masd.vue';
import videoPreview from '../../common/components/video-preview/video-preview.vue';
import videoPlayer from '../../common/components/video-player/video-player.vue';
import BranchAtmWidgetWrapper from '../../applications/branch-atm-widget-wrapper/branch-atm-widget-wrapper.vue';
import layoutBase from '../layout-base';

Vue.component('macc', macc);
Vue.component('mevp', mevp);
Vue.component('mstb', mstb);
Vue.component('mtbl', mtbl);
Vue.component('mtab', mtab);
Vue.component('mdtb', mdtb);
Vue.component('mslc', mslc);
Vue.component('mjbl', mjbl);
Vue.component('meda', meda);
Vue.component('mnib', mnib);
Vue.component('msup', msup);
Vue.component('mfch', mfch);
Vue.component('meok', meok);
Vue.component('mltl', mltl);
Vue.component('masd', masd);
Vue.component('video-player', videoPlayer);
Vue.component('video-preview', videoPreview);
Vue.component('branch-atm-widget-wrapper', BranchAtmWidgetWrapper);
Vue.component('vue-table-of-content', VueTableOfContent);

/* eslint-disable no-new*/
new Vue({
	extends: layoutBase,
	el: '[data-layout-id=AV9IE-app]'
});
/* eslint-enable*/
