import mevp from '../MEVP/mevp';
export default {
	data() {
		return {
			detailsOpened: false
		};
	},
	components: {
		mevp
	}
};
